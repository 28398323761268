<div
    class="wrapper">

    <app-msg-banner
        *ngIf="showNotification" [msgList]="messageList"
        (msgListChange)="messageList = $event"
    ></app-msg-banner>

    <mat-card
        fxLayout="column"
        fxLayoutGap="20px"
        class="mat-elevation-z2">

        <!-- search form -->
        <!-- Filter menu -->
    <mat-accordion>
        <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
            <span class="material-icons filter-title">filter_alt</span>
            <span class="filter-title">Filter Activity Groups</span>
        </mat-expansion-panel-header>

        <form
            fxLayout="column"
            [formGroup]="searchForm">

              <div
                fxLayout="row wrap"
                fxLayoutGap="3%"
                fxLayout.lt-md="column"
                fxLayoutGap.lt-md="0%">

                <mat-form-field
                  fxFlex="30%"
                  fxFlex.lt-md="100%">
                  <mat-label>Account</mat-label>
                  <mat-select
                    formControlName="chainGroups"
                    [multiple]="true"
                    required
                    #chainMultiSelect>
                    <mat-option>
                      <ngx-mat-select-search
                        [showToggleAllCheckbox]="true"
                        (toggleAll)="toggleChainsSelectAll($event)"
                        [formControl]="chainMultiFilterCtrl"
                        placeholderLabel="Find chain group..."
                        noEntriesFoundLabel="'No matching chain group found'"
                        [toggleAllCheckboxTooltipMessage]="tooltipMessage"
                        [toogleAllCheckboxTooltipPosition]="'above'"
                      >
                        <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                      *ngFor="let group of filteredChainsMulti | async"
                      [value]="group"> {{ group.desc }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field
                  fxFlex="30%"
                  fxFlex.lt-md="100%">
                  <mat-label>Type</mat-label>
                  <mat-select
                    [disabled]="isTerrOrMerchManager()"
                    required
                    [formControl]="searchForm.controls['type']"
                  >
                    <mat-option>--All--</mat-option>
                    <mat-option *ngFor="let type of activityType" [value]="type.actTypeId">
                      <span *ngIf="type.actTypeId !== getActivityTypes.SHIPPER_COUNT">{{ type.en }}</span>
                      <span *ngIf="type.actTypeId === getActivityTypes.SHIPPER_COUNT">{{ getEnvironment.shipperName }}</span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field
                  fxFlex="30%"
                  fxFlex.lt-md="100%">
                  <mat-label>Start Date Range</mat-label>
                  <mat-date-range-input [rangePicker]="startPicker">
                    <input matStartDate formControlName="startBeginDate"
                           placeholder="Start date">
                    <input matEndDate formControlName="startFinishDate"
                           placeholder="End date">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="startPicker" (click)="startPicker.open()"></mat-datepicker-toggle>
                  <mat-date-range-picker #startPicker></mat-date-range-picker>

                  <mat-error *ngIf="searchForm.controls.startBeginDate.hasError('matStartDateInvalid')">
                    Invalid start date</mat-error>
                  <mat-error *ngIf="searchForm.controls.startFinishDate.hasError('matEndDateInvalid')">
                    Invalid end date</mat-error>
                </mat-form-field>

                <mat-form-field
                  fxFlex="30%"
                  fxFlex.lt-md="100%"
                >
                  <mat-label>Activity Status</mat-label>
                  <mat-select
                    formControlName="activityStatus"
                    placeholder="Activity Status"
                    [multiple]="true"
                    #staMultiSelect
                    (selectionChange)="onChange($event)"
                    required
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        [showToggleAllCheckbox]="true"
                        (toggleAll)="toggleStatusSelectAll($event)"
                        [toggleAllCheckboxTooltipMessage]="tooltipMessage"
                        [toogleAllCheckboxTooltipPosition]="'above'"
                        [formControl]="staMultiFilterCtrl"
                        placeholderLabel="Find activity status..."
                        noEntriesFoundLabel="'No matching account found'"
                      >
                        <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                      *ngFor="let sta of filteredStaMulti | async"
                      [value]="sta"
                    >
                      {{ getActivityStatusById(sta) }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field
                    fxFlex="30%"
                    fxFlex.lt-md="100%">
                    <mat-label> Season </mat-label>
                    <mat-select formControlName="season">
                        <mat-option>--All--</mat-option>
                        <mat-option *ngFor="let season of seasons | activeFilter:filterArgs" value="{{ season.seasId }}">
                        {{ season.en }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field
                    fxFlex="30%"
                    fxFlex.lt-md="100%">
                    <mat-label>End Date Range</mat-label>
                    <mat-date-range-input [rangePicker]="endPicker">
                    <input matStartDate formControlName="endBeginDate"
                            placeholder="Start date">
                    <input matEndDate formControlName="endFinishDate"
                            placeholder="End date">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="endPicker" (click)="endPicker.open()"></mat-datepicker-toggle>
                    <mat-date-range-picker #endPicker></mat-date-range-picker>

                    <mat-error *ngIf="searchForm.controls.endBeginDate.hasError('matStartDateInvalid')">
                    Invalid start date</mat-error>
                    <mat-error *ngIf="searchForm.controls.endFinishDate.hasError('matEndDateInvalid')">
                    Invalid end date</mat-error>
                </mat-form-field>

                <mat-form-field
                  fxFlex="30%"
                  fxFlex.lt-md="100%">
                  <mat-label>Activity Title</mat-label>
                  <input formControlName="title" matInput placeholder="Activity Title" type="text"/>
                  <mat-error *ngIf="searchForm.controls.title.errors?.pattern && searchForm.controls.title.dirty">
                    * Input not allowed
                  </mat-error>
                </mat-form-field>

                <mat-form-field
                  fxFlex="30%"
                  fxFlex.lt-md="100%">
                  <mat-label>Location</mat-label>
                  <mat-select formControlName="location">
                    <mat-option>--All--</mat-option>
                    <mat-option *ngFor="let location of locations | activeFilter:filterArgs" value="{{ location.locId }}">
                      {{ location.en }}</mat-option>
                  </mat-select>
                </mat-form-field>

            </div>

                <div
                    fxFlex
                    fxLayout="row"
                    fxLayout.lt-md="column"
                    fxLayoutAlign="space-between center"
                    fxLayoutAlign.lt-md="start"
                    class="buttons">

                    <div
                    fxLayoutAlign="start"
                    fxLayout="row"
                    fxLayout.lt-md="column">
                        <button
                            fxFlex.lt-md="100%"
                            class="btn btn-primary"
                            mat-raised-button
                            style="background-color: #42a5f5"
                            [disabled]="searchForm.invalid"
                            type="button"
                            (click)="onSearch()"
                            [disabled]="this.searchForm.invalid"
                            >Search</button>
                        <button
                            fxFlex.lt-md="100%"
                            class="btn btn-primary"
                            mat-raised-button
                            (click)="onClear()"
                            style="background-color: #f44336"
                            type="button">Clear</button>
                    </div>

                <mat-slide-toggle
                    fxHide.lt-md
                    fxShow
                    fxFlex.lt-md="100%"
                    fxLayoutAlign="end"
                    formControlName="expiredOld"
                    >Expired (> 14 days)</mat-slide-toggle>

                </div>
            </form>
        </mat-expansion-panel>
    </mat-accordion>

        <!-- table -->
        <div
            class="scrollable">
            <table mat-table matSort
                [dataSource]="dataSource"
                (matSortChange)="changeSort($event)"
                class="tableLayout mat-elevation-z8"
                *ngIf="dataSource.data.length && !isLoadingResults">

                <ng-container *ngFor="let col of tableColumns; index as i" [matColumnDef]="col.colDef">
                    <th mat-header-cell *matHeaderCellDef class="{{ col.colWidth }}" [resizeColumn]="true" [index]="i" >
                      <span mat-sort-header [disabled]="!col.colSort" >{{ col.colHeader }}</span> </th>

                    <!-- data  -->
                    <td mat-cell *matCellDef="let row">
                      <span *ngIf="col.colType === columnType.STRING" title="{{ row[col.colDef] }}"
                        class="{{ col.colWidth }}-m"> {{ row[col.colDef] }}
                      </span>
                      <span *ngIf="col.colType === columnType.LONG_STRING" title="{{ row[col.colDef] }}"
                        class="{{ col.colWidth }}-m"> {{ row[col.colDef] }}
                      </span>
                      <span *ngIf="col.colType === columnType.LONG_STRING_NULL" title="{{ row[col.colDef] }}"
                        class="{{ col.colWidth }}-m"> {{ row[col.colDef] ? row[col.colDef] : row[col.ifNullColDef] }}
                      </span>
                      <span *ngIf="col.colType === columnType.DATE && row[col.colDef] != null"
                        class="{{ col.colWidth }}-m"> {{ row[col.colDef] | dateFormat}}
                      </span>
                      <span *ngIf="col.colType === columnType.DATE && row[col.colDef] == null"
                            class="{{ col.colWidth }}-m"> </span>
                      <span *ngIf="col.colType === columnType.ACTIVITY_TYPE"
                        class="{{ col.colWidth }}-m"> {{ getActivityType(row[col.colDef]) }}
                      </span>
                      <span *ngIf="col.colType === columnType.SEASON_TYPE"
                        class="{{ col.colWidth }}-m"> {{ getSeason(row[col.colDef]) }}
                      </span>
                      <span *ngIf="col.colType === columnType.LOCATION_TYPE"
                        class="{{ col.colWidth }}-m"> {{ getLocation(row[col.colDef])  }}
                      </span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"
                    (click)="editSelected(row)"></tr>
            </table>
        </div>

        <div fxLayout="row"
			fxLayoutAlign="end">
			<mat-paginator
				[hidden]="!dataSource.data.length"
				[length]="resultsLength"
				[pageSizeOptions]="[10, 25, 50, 75, 100]"
				[pageSize]="10"
				(page)="onPageChange($event)"
			></mat-paginator>
		</div>

    </mat-card>

</div>
