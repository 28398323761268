import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LoadResponsesConfig} from '../../../shared/models/activity/load-responses-config.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MsgBannerService} from '../../../shared/components/msg-banner/msg-banner.service';
import {AdminService} from '../../../core/services/admin.service';
import {LoadResponsesComponent} from '../../../shared/components/load-responses/load-responses.component';
import {CustomFieldsValidators} from '../../../shared/components/custom-validators/CustomFieldsValidators';
import {environment} from '../../../../environments/environment';
import { ChainGroupModel } from '../../../shared/models/chain';
import { GraphService } from '../../../core/graph/graph.service';
import { InstallationType, ResponseGroupType } from '../../../shared/models/activity/response-group-type.model';

@Component({
  selector: 'app-response-panel',
  templateUrl: './response-panel.component.html',
  styleUrls: ['./response-panel.component.scss']
})
export class ResponsePanelComponent implements OnInit {
  @Output() onUpdate: EventEmitter<any> = new EventEmitter();
  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  @Input() config: LoadResponsesConfig;
  @Input() object: any;
  @Input() parent;
  @ViewChild(LoadResponsesComponent) child: LoadResponsesComponent;

  responseSetForm: FormGroup;
  chainGroups: ChainGroupModel[] = [];
  responseGroupTypes: ResponseGroupType[] = [];
  installationTypes: InstallationType[] = [];

  messageList = [];
  showNotification = false;

  constructor(private fb: FormBuilder,
              private adminService: AdminService,
              private graphService: GraphService,
              private msgBanner: MsgBannerService) {
    this.responseSetForm = this.fb.group({
      respGroupId: [ [''] ],
      title: [ [''], [Validators.required, Validators.pattern(environment.emojiRegEx), CustomFieldsValidators.maxTitleOfSets]],
      chainGroupId: [ null ],
      respGroupType: [ null, [Validators.required] ],
      installationType: [ null, [Validators.required] ],
      responses: [ [] ]
    });
  }

  ngOnInit(): void {
    this.responseSetForm.controls.respGroupId.setValue(this.object?.respGroupId === undefined ? null : this.object?.respGroupId);
    this.responseSetForm.controls.title.setValue(this.object?.title);
    this.responseSetForm.controls.chainGroupId.setValue(this.object?.chainGroupId);
    this.responseSetForm.controls.respGroupType.setValue(this.object?.respGroupType);
    this.responseSetForm.controls.installationType.setValue(this.object?.installationType);
    this.responseSetForm.controls.responses.setValue(this.config?.data == null ? [] : this.config.data);
    this.getChainGroups();
    this.getResponseGroupTypes();
  }

  change(child, action: string) {
    this.showNotification = false;
    this.responseSetForm.controls.responses.setValue(child.dataSource.data);
    if (this.responseSetForm.invalid) {
      this.showNotification = true;
      this.msgBanner.addMsgError(this.messageList, 'Please complete all required fields.');
      return;
    }
    if (this.responseSetForm.controls.responses.value.length <= 0) {
      this.showNotification = true;
      this.msgBanner.addMsgWarning(this.messageList, 'You need to have at least one response for this set.');
      return;
    }

    switch (action) {
      case 'create':
        this.createResponseSet();
        break;
      case 'update':
        this.updateResponseSet();
        break;
    }
  }

  createResponseSet() {
    this.adminService.create(this.responseSetForm.value).subscribe(
      response => {
        this.parent.close(response);
      }, error => {
        this.showNotification = true;
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
      }
    );
  }

  updateResponseSet() {
    this.adminService.update(this.responseSetForm.value).subscribe(
      response => {
        this.onUpdate.emit(response);
      }, error => {
        this.showNotification = true;
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
      }
    );
  }

  cancel() {
    this.showNotification = false;
    this.adminService.findAllById(this.responseSetForm.controls.respGroupId.value).subscribe(
      response => {
        this.responseSetForm.controls.responses.setValue(response.responses);
        this.responseSetForm.controls.title.setValue(response.title);
        this.onCancel.emit(this.responseSetForm.value);
      }, error => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      }
    );
  }

  public reset() {
    this.ngOnInit();
    this.child.config.data = this.config.data;
    this.child.ngOnInit();
    this.child.form.reset();
  }

  getLength() {
    return environment.lengthValidators;
  }

  getChainGroups() {
    const email = this.graphService.user.displayName;
    this.adminService.getChainGroupsByEmail(email).subscribe(
      (result: ChainGroupModel[]) => {
        this.chainGroups = result;
      },
      (error: any) => {
        this.msgBanner.addMsgError(this.messageList, 'Could not extract the chain groups. Please contact your administrator. ');
      }
    );
  }

  getResponseGroupTypes() {
    this.adminService.getResponseGroupTypes().subscribe(
      (result: ResponseGroupType[]) => {
        this.responseGroupTypes = result;
        this.updateInstallationType();

        const respGroupType = this.responseGroupTypes.find(r => r.id === this.object?.respGroupType);
        this.responseSetForm.controls.respGroupType.setValue(respGroupType?.id);
        const instType = this.installationTypes.find(r => r.id === this.object?.installationType);
        this.responseSetForm.controls.installationType.setValue(instType?.id);
      },
      (error: any) => {
        this.msgBanner.addMsgError(this.messageList, 'Could not extract the chain groups. Please contact your administrator. ');
      }
    );
  }

  respGrpTypeSelected() {
    return this.responseSetForm.controls.respGroupType.value !== null;
  }

  updateInstallationType() {
    const selectedGroup = this.responseSetForm.controls.respGroupType.value;
    this.installationTypes = this.responseGroupTypes.find(r => r.id === selectedGroup)?.installations;
  }

  clearInstallationType() {
    this.responseSetForm.controls.installationType.setValue(null);
  }
}
